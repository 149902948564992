var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class='link' href='"
    + alias4(((helper = (helper = lookupProperty(helpers,"href") || (depth0 != null ? lookupProperty(depth0,"href") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"href","hash":{},"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":30}}}) : helper)))
    + "' rel='"
    + alias4(((helper = (helper = lookupProperty(helpers,"rel") || (depth0 != null ? lookupProperty(depth0,"rel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rel","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":44}}}) : helper)))
    + "' aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"aria-label") || (depth0 != null ? lookupProperty(depth0,"aria-label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"aria-label","hash":{},"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":72}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"items") || (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"items","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":83}}}) : helper)))
    + "</a>";
},"useData":true});